import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/vacancies-section1";
import Section2 from "../sections/vacancies-section2";
import SEO from "../components/seo";

export default function Vacancies() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.white}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >

      <SEO title={"Job Vacancies"} description={"Sign up or refer a friend to one of vacacies provided by WomenWorks networks"} />
      
      <Section1/>
      <Section2
        title="Job Vacancies - AwanTunai"
        logo="/vacancies/awantunai.png"
        companyLink="https://awantunai.com"
        peopleWhoWorksHere={{
          image:"/mentor/windy-natriavi.png",
          name:"Windy Natriavi",
          title:"CPO at AwanTunai, Cofounder WomenWorks"
        }}
        companyDescriptionP1="AwanTunai is a growing VC-backed startup that provide SaaS platform for traditional offline wholesalers and merchants, offering them a new experience to manage their stores in the digital era, through inventory ordering, digital payment, customer management, and inventory financing. We are working on dual mission to (1) digitize the conventional FMCG supply chain MSME's business process and (2) provide affordable financing to support their business grow."
        companyDescriptionP2="We are looking for people who live and breath Awan Tunai's cultural values and internalize those values in their work: Building Trust, Executing Fast, and Keep Experimenting and Improving." 
        vacancyList={[
          {
            position: "VP of Product",
            description: "You will lead two verticals with amazing Head of Products & product teams and you be required to collaborate across functions and support teams to deliver the company's vision and strategy.",
            expandable: {
              expand: true,
              title : "Requirements",
              content: [
                "+5 years of experience in product management/as management consultant in complex/matrix environments.",
                "University degree in the field of computer science, engineering, or another rigorous discipline. An advanced degree in one of these fields is a plus.",
                "Strong customer-centric approach and ability to envision products that solve customers’ pain points.",
                "Ability to articulate ideas to both technical and non-technical audiences.",
                "Extensive knowledge of and proven track record in development of products and services in a technology-based environment.",
                "Strong understanding of customers and requirements in the fintech industry.",
                "Strong leadership skill."]
            }
          },
            {position:"VP of Financial Product", 
            description:"You will lead three verticals with amazing PMs and you be required to collaborate across functions and support teams to deliver the company's vision and strategy.",
            expandable: {
              expand: false,
              title : "Requirements",
              content: [
                "+5 years of experience in product management/as management consultant in complex/matrix environments.",
                "University degree in the field of computer science, engineering, or another rigorous discipline. An advanced degree in one of these fields is a plus.",
                "Strong customer-centric approach and ability to envision products that solve customers’ pain points.",
                "Ability to articulate ideas to both technical and non-technical audiences.",
                "Familiarity with information security, privacy and operational risk management requirements in the financial service industry.",
                "Extensive knowledge of and proven track record in development of products and services in a technology-based environment.",
                "Strong understanding of customers and requirements in the fintech industry.",
                "Strong leadership skill."]
              }
            },
            {position:"Jr PM Agent Network",
            description:"The position will enable you to interacts with a broad spectrum of stakeholders including management at varying levels, developers, architects, designers, business leaders and various associated stakeholders including external clients.",
            expandable: {
              expand: false,
              title : "Requirements",
              content: [
                "Bachelor degree with minimum 2+ years’ experience in product management. Computer science background is a plus.",
                "Great communication, documentation, and presentation skills, positive energy, able to help identify, facilitate, and drive a business solution to completion.",
                "Strong interpersonal skills with both external and internal contacts and ability to lead projects involving multiple functional areas to meet critical deadlines.",
                "Experience working in a fast-paced, collaborative, agile environments.",
                "Understanding of the software development life cycle and emerging technologies.",
                "Strong analytical and quantitative skills; ability to use hard data and metrics to back up assumptions and recommendations.",
                "Excellent communication skills that allow you to articulate your ideas to engineers and business stakeholder."]
              }
            },
            {position:"Jr PM Credit Risk",
            description:"The position will enable you to interacts with a broad spectrum of stakeholders including management at varying levels, developers, architects, designers, business leaders and various associated stakeholders including external clients.",
            expandable: {
              expand: false,
              title : "Requirements",
              content: [
                "Develop requirements and specifications for new applications, processes and initiatives, or analyses change requests/enhancements in existing applications.",
                "Collaborates closely with the business areas to identify requirements, project specifications and business case as well as ensures that the solutions satisfy business needs and implemented successfully.",
                "Stays with the project from inception to delivery, while guiding teams of Product designer, analyst, researcher, growth, ops and engineering.",
                "Work with customers and multiple team to understand business demands that drive the analysis and design of quality business solutions.",
                "Describe the user experience and app features.",
                "Involved in the full app features development life cycle, including UI/UX best suited for consumer.",
                "Executes production tasks according to a documented schedule that meets or exceeds customer expectations.",
                "Ensures the consistency and maintainability of existing applications by creating, maintaining, and enforcing standards/procedures for implementing technical solutions."
                ]
              }
            },
        ]}
        applyLink="https://docs.google.com/forms/d/e/1FAIpQLSeMzwAWZVaiuIWI8TLqjJSE3T5N9FNzZT-Ab-TxfN5AR8Kt0A/viewform?usp=pp_url&entry.1358560659=AwanTunai&entry.1008683240="
        referLink="https://docs.google.com/forms/d/e/1FAIpQLSdoDZsVzmShkfoHw1zDJcewUr6Fm_yVOCYyJrdtRJgNF-pIOw/viewform?usp=pp_url&entry.1358560659=AwanTunai&entry.1008683240="
      />
      <Section2
        title="Job Vacancies - Impactto"
        logo="/vacancies/impactto.png"
        companyLink="https://impactto.io"
        companyDescriptionP1="As a collective builder platform, we do believe in the democratization of knowledge and fairness of opportunity when it comes to empowering Indonesia’s startup ecosystem. That’s why we make it easy for early-stage startups to unlock their potential by building bridges between them and relevant collaborators in order to achieve product-market fit. To make it happen, we provide the early-stage startup founders with our understanding of the local context, practical methods in creating strategies, and extensive business networks."
        vacancyList={[
            {position:"Project Manager", 
            description:"As the Project Manager, your responsibilite will include\n" + 
            "1) Data-based analysis on the pain points of tech startups/ stakeholders involved\n" +
            "2) Formulating solutions or program ideas based on the customer perspectives\n"+
            "3) Planning the execution of solutions or program ideas\n"+
            "4) Mapping related stakeholders needed to be involved in solving the pain points\n"+
            "5) Mapping related stakeholders needed to be involved in solving the pain points",
            expandable: {
              expand: false,
              title : "Requirements",
              content: [
                "Bachelor Degree (Fresh graduate) from a reputable university majoring in Industrial Engineering, Business, or Management is preferred",
                "Enjoys working in a collaborative, agile, and iterative environment",
                "The desire to have purpose and create greater good",
                "Data-driven thinking and reasoning skills",
                "Have strong written and verbal english",
                "Previous experience in organization or project management (Preferred)"
                ]
              },
              textBeforeButtons: "Do you meet these criterias? Don't hesitate to click Apply Now below!",
          },
        ]}
        applyLink="https://docs.google.com/forms/d/e/1FAIpQLSeMzwAWZVaiuIWI8TLqjJSE3T5N9FNzZT-Ab-TxfN5AR8Kt0A/viewform?usp=pp_url&entry.1358560659=Impactto&entry.1008683240="
        referLink="https://docs.google.com/forms/d/e/1FAIpQLSdoDZsVzmShkfoHw1zDJcewUr6Fm_yVOCYyJrdtRJgNF-pIOw/viewform?usp=pp_url&entry.1358560659=Impactto&entry.1008683240="
      />
      <Section2
        title="Job Vacancies - Lumina"
        logo="/vacancies/lumina.svg"
        companyLink="https://lumina.mba/"
        companyDescriptionP1="Lumina is Indonesia’s fast growing online community for productive workers. Discover thousands job opportunities at Lumina easily, safely and quickly."
        companyDescriptionP2="Be a part of a supportive community, gain insights and get a chance to get new job opportunities!"
        vacancyList={[
            {position:"Chief of Growth", 
            description:"Lumina is looking for a founding team member to lead exponential Growth!",
            expandable: {
              expand: false,
              title : "Requirements",
              content: [
                "Someone who has had exposure on working with blue and grey collar positions in past roles, deep understanding of audience and user segmentation",
                "Min of 5-6 years of experience (ideally 8-10 years)"+
                "Strong understanding of online and offline growth hacking"+
                "Understanding of paid and content marketing, ideally has an understanding on SEO",
                "Strong empathy towards blue and grey collar topics",
                "Harnesses critical thinking and experimentation",
                ]
              },
          },
        ]}
        applyLink="https://docs.google.com/forms/d/e/1FAIpQLSeMzwAWZVaiuIWI8TLqjJSE3T5N9FNzZT-Ab-TxfN5AR8Kt0A/viewform?usp=pp_url&entry.1358560659=Lumina&entry.1008683240="
        referLink="https://docs.google.com/forms/d/e/1FAIpQLSdoDZsVzmShkfoHw1zDJcewUr6Fm_yVOCYyJrdtRJgNF-pIOw/viewform?usp=pp_url&entry.1358560659=Lumina&entry.1008683240="
      />
    </Layout>   
  )
}
